
#nprogress .bar {
  background: #eb7625 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #eb7625, 0 0 5px #eb7625;
}

#nprogress .spinner-icon {
  border-top-color: #eb7625;
  border-left-color: #eb7625;
}